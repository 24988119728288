import { useQuery } from "@tanstack/react-query";
import { programsUrl } from "../../constants/api/endpoints";
import { useAxios } from "../../hooks/axios/useAxios";

interface GetProgramsParams {
  institution_id?: string,
  portfolio_id?: string | null,
  program_id?: string,
  searchValue?: string,
  sortingValue?: string,
  name_only?: boolean,
  current_page?: number,
  filter?: string
}

export const useQueryPrograms = (params: GetProgramsParams) => {
  const {
    institution_id,
    portfolio_id,
    program_id,
    searchValue,
    sortingValue,
    name_only,
    current_page,
    filter
  } = params;

  const axios = useAxios();

  const handleRequest = () => {
    const queryParams: Record<string, any> = {
      institution_id,
      portfolio_id,
      program_id,
      search_term: searchValue,
      ordering: sortingValue,
      name_only,
      current_page,
    };

    if (filter) {
      queryParams.filter = filter;
    }

    return axios.get(programsUrl, { params: queryParams })
      .then(response => {
        if (program_id) {
          return {
            ...response?.data?.programs[0],
            catalogue_url: response?.data.catalogue_url
          };
        } else if (name_only) {
          return response?.data?.programs ?? response;
        } else {
          return response?.data ?? response;
        }
      });
  }

  return useQuery({ queryKey: ['programs'], queryFn: handleRequest });
};
